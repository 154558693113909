<template>
  <section>
    <b-modal v-model="visible" id="update-target" centered hide-footer :title="this.$t('monitoring.edit_target')">
      <form @submit.prevent="updateTarget">
        <div class="dialog-content">
          <div class="form-group">
            <input v-model="alias" :placeholder="this.$t('monitoring.target')" class="form-control">
          </div>
          <div class="form-group">
            <b-form-textarea :placeholder="this.$t('monitoring.note')"  v-model="note" style="min-height: 100px"></b-form-textarea>
          </div>
        </div>
        <div v-if=" target.active_task && timeLeftCounter(target.active_task.expires_at) < 48">
            <span style="display: flex">
              <span>{{$t('monitoring.continue_monitoring')}}</span>
              <b-form-select v-model="period" :options="periodList" style="margin-left: 8px; margin-right: 8px"></b-form-select>
              <span>{{$tc('monitoring.continue_day', period)}}</span>
              <!-- <span v-if="period < 7">{{$t('monitoring.continue_day')}}</span>
              <span v-else>{{$t('monitoring.continue_days')}}</span> -->
            </span>
        </div>



<!--        <div class="checkboxServices m-t-5" v-if="target.active_task">-->
<!--          <span>{{$t('monitoring.monitoring_services')}}</span>-->
<!--          <div v-for="source in sources.data" class="serviceSelect border-checkbox-section">-->
<!--            <div class="border-checkbox-group border-checkbox-group-primary" v-if="source.monitoring_params.includes(target.param) ">-->
<!--              <input type="checkbox" class="border-checkbox" :id="`service_${source.id}`"-->
<!--                     :disabled="!source.is_active || !serviceCanBeSelected(source.id)"-->
<!--                     v-model="service_ids" :value="source.id">-->
<!--              <label :for="`service_${source.id}`"  class="border-checkbox-label checkboxFlex">-->
<!--                <div style="display: flex;align-items: flex-start">-->
<!--                  <div style="width: auto">-->
<!--                    {{ nameChange(source.name) }}-->
<!--                  </div>-->
<!--                  <div style="margin-left: 8px" v-if="!serviceCanBeSelected(source.id)">-->
<!--                    <tippy-->
<!--                        style="display: flex; margin-right: 10px"-->
<!--                        interactive-->
<!--                        :animate-fill="false"-->
<!--                        placement="top"-->
<!--                        distant="7"-->
<!--                        theme="light"-->
<!--                        animation="shift-toward"-->
<!--                        trigger="mouseenter"-->
<!--                        offset="interactive:true"-->
<!--                        arrow>-->
<!--                      <template v-slot:trigger>-->
<!--                        <i class="feather icon-info" ></i>-->
<!--                      </template>-->
<!--                      <div class="alignLeft">-->
<!--                        <div><span style="font-weight: 700">{{ nameChange(source.name) +': ' }}</span>{{status(source.name, target)}} </div>-->
<!--                        <div>{{ $t('monitoring.next_service_check')+': '+timeLeftForNextStatusCheck(source.name, target)}}</div>-->
<!--                      </div>-->
<!--                    </tippy>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->



        <footer class="d-grid gap-2 m-t-15">
          <b-button type="submit" variant="primary" class="btn"
                    :disabled="$v.$invalid">{{ $t('user.save') }}
            <span v-if="isLoading">
              <b-spinner small></b-spinner>
            </span>
          </b-button>
          <b-button type="button" variant="secondary"
                    @click="closeDialog" class="btn">{{ $t('user.cancel') }}
          </b-button>
        </footer>
      </form>
    </b-modal>
  </section>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import {helpers, maxLength, minLength, required} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import _ from "underscore";
import moment from "moment";


export default {
  name: "updateTarget",
  props: ['target'],
  components: {
    VueTelInput
  },
  setup() {
    return {
      moment
    }
  },
  data() {
    return {
      visible: false,
      alias: '',
      note: '',
      period: undefined,
      isLoading: false,
      service_ids: [],
      periodList: [3,7,10,14,21,30]
    }
  },

  validations() {
    let validation = {
      alias: {
        required,
        maxLength: maxLength(255)
      },
      note: {
        maxLength: maxLength(255)
      },
    }
    return validation
  },
  computed: {
    ...mapGetters('monitoring', ['sources', 'currentMonitoringCount']),
  },
  methods: {
    oneServiceNameCheck(service){
      let source = service
      if (service === 'VKMonitor'){
        source = "VKontakte"
      }
      return source
    },
    status(source, target){
      let currentStatus;
      if (target.online_statuses.length){
        currentStatus = target.online_statuses.find(status => status.source === source)
      }
      if (typeof currentStatus !== 'undefined' && currentStatus.status){
        return this.$t('monitoring.'+ currentStatus.status)
      }
    },
    timeLeftForNextStatusCheck(source, target){
      let currentStatus = target.online_statuses.find(status => status.source === source)
      let updateDate = moment(currentStatus.updated_at, 'X').add(24, 'hour').format('DD.MM.YYYY, HH:mm')
      return updateDate
    },

    serviceCanBeSelected(serviceId){
      let currentService = this.target.online_statuses.find(status=> status.service_id === serviceId)
      if (typeof currentService !== 'undefined' && (currentService.status === 'non-existent' || currentService.status === 'hidden')){
        return ((this.timeLeftCounter(currentService.updated_at) + 24) < 0 )
      } else {
        return true
      }
    },
    // setSelectedServices(){
    //   this.service_ids = [];
    //   if (this.target.active_task &&  this.target.active_task.services){
    //     console.log('1')
    //     console.log(this.target)
    //     this.target.active_task.services.forEach(service=> {
    //       if (service.active){
    //         this.service_ids.push(service.id)
    //       }
    //     })
    //   }
    // },
    timeLeftCounter(val){
      let left;
      let eventTime = moment(val, 'X')
      let now = moment();
      left = eventTime.diff(now, 'hours')
      return left
    },
    nameChange(val){
      let name
      if (val === 'VKMonitor'){
        name = 'VKontakte'
      } else {
        name = val
      }
      return name
    },
    setTargetVals(){
      this.alias = this.target.alias
      if (this.target.note){
        this.note = ''
        this.note = JSON.parse(JSON.stringify(this.target.note))
      }
      this.visible = true;
    },
    showDialog() {
      this.period = undefined
      // this.setSelectedServices();
      this.setTargetVals();
    },
    closeDialog() {
      this.alias = '';
      this.note = '';
      this.visible = false;
    },
    updateTarget() {
      this.isLoading = true
      let  innerPayload = {
        alias: this.alias,
      }
      if (this.note.trim() !== ''){
        innerPayload.note = this.note
      } else {
        innerPayload.note = null
      }
      let targetPayload = {
        id: this.target.id,
        payload: innerPayload
      }
      this.$store.dispatch('monitoring/updateTarget', targetPayload).then(()=>{
        this.$store.dispatch('monitoring/getTargets').then(() => {
          this.isLoading = false;
          this.visible = false
        })
      })
    },
  },
}
</script>

<style scoped>

</style>