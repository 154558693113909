<template>

  <div class="card">
    <div v-if="isLoading" style="padding: 20px">
      <b-skeleton width="80%" :animated="true"></b-skeleton>
      <b-skeleton width="40%" :animated="true"></b-skeleton>
      <b-skeleton width="20%" :animated="true"></b-skeleton>
      <b-skeleton width="60%" :animated="true"></b-skeleton>
      <b-skeleton width="80%" :animated="true"></b-skeleton>
      <b-skeleton :animated="true"></b-skeleton>
    </div>
    <div v-if="!isLoading" class="targetTable">
      <div class="card-header" style="padding-bottom: 0;">
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex; align-items: center">
            <h4 class="header-title">{{ $t("monitoring.targets") }}</h4>
          </div>
          <div class="icons">
            <b-button class="btn btn-primary" v-if="!isEdit && this.targets.length"  @click="editTargets" :content="$t('monitoring.edit_targets')" v-tippy="{ placement : 'top',  arrow: true }" style="display:flex;align-items: center">
              <i class="feather icon-edit"></i>
              <div class="targetTitle m-l-10 " style="text-transform: none">{{ $t("monitoring.edit_targets") }}</div>
            </b-button>

            <b-button class="btn btn-primary" v-if="isEdit && this.targets.length" @click="editTargets" :content="$t('monitoring.display_targets')" v-tippy="{ placement : 'top',  arrow: true }" style="display:flex;align-items: center">
              <i class="feather icon-arrow-left"></i>
              <div class="targetTitle m-l-10 " style="text-transform: none">{{ $t('monitoring.display_targets')}}</div>
            </b-button>

          </div>
        </div>
      </div>
      <div class="card-content">
        <div>
          <div class="scroll-container">
            <b-table
              v-if="targets.length"
              :items="targets"
              :fields="fields"
              :busy="isLoading"
              small
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
  
              <template #cell(alias)="row">
                <span v-if="row.value.length <= 25">{{row.value}}</span>
                <span v-else :class="{'hideText': row.value.length > 25}" :content="row.value" v-tippy="{ placement : 'top',  arrow: true  }">{{row.value}}</span>
                <i class="feather icon-file-text f-16 m-l-10" v-if="row.item.note" :content="`<span class='f-w-700'>${$t('monitoring.note')}</span>`+': '+row.item.note" style="color:#bebebe;" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
              </template>
  
  
  
              <template #cell(value)="row">
                <div style="display: flex;justify-content: flex-start; align-items: center">
                  <span v-if="row.value && row.value.length <= 25">{{row.value}}</span>
                  <span v-else :class="{'hideText': row.value.length > 25}" :content="row.value" v-tippy="{ placement : 'top',  arrow: true }">{{row.value}}</span>
                  <span v-if="row.item.online_statuses.length > 0" v-for="status in row.item.online_statuses" 
                    :class="{'not-found-info': status.status === 'user not found'}">
                      <span
                          v-if="status.source === 'Telegram' && status.status === 'recently'"
                          class="m-l-15"
                          :content="hideUserServices(row.item.online_statuses)" v-tippy="{ placement : 'top',  arrow: true }"
                          style="cursor: pointer;display: flex;justify-content: center;align-items: center">
                          <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 17.5C21 19.433 19.433 21 17.5 21C15.567 21 14 19.433 14 17.5C14 15.567 15.567 14 17.5 14C19.433 14 21 15.567 21 17.5Z" stroke="#1C274C" stroke-width="1.5"/>
                            <path d="M2 11H22" stroke="#1C274C" stro ke-width="1.5" stroke-linecap="round"/>
                            <path d="M4 11L4.6138 8.54479C5.15947 6.36211 5.43231 5.27077 6.24609 4.63538C7.05988 4 8.1848 4 10.4347 4H13.5653C15.8152 4 16.9401 4 17.7539 4.63538C18.5677 5.27077 18.8405 6.36211 19.3862 8.54479L20 11" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M10 17.5C10 19.433 8.433 21 6.5 21C4.567 21 3 19.433 3 17.5C3 15.567 4.567 14 6.5 14C8.433 14 10 15.567 10 17.5Z" stroke="#1C274C" stroke-width="1.5"/>
                            <path d="M10 17.4999L10.6584 17.1707C11.5029 16.7484 12.4971 16.7484 13.3416 17.1707L14 17.4999" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                          </svg>
                      </span>
                      <span v-if="status.status === 'user not found'" class="m-l-15"> <i class="feather icon-user-x"  :content="notFoundUserServices(row.item.online_statuses)" v-tippy="{ placement : 'top',  arrow: true }"></i></span>
                  </span>
                </div>
              </template>


              <template #cell(active_task.expires_at)="row">
                <div v-if="typeof row.value !== 'undefined' && row.value">
                  <span :content="$t('monitoring.expires_alert')" v-tippy="{ placement : 'top',  arrow: true }"
                        v-if="timeLeftCounter(row.value) < 48"
                    :class="{
                      'alert48': timeLeftCounter(row.value) < 48 && timeLeftCounter(row.value) >= 24,
                      'alert24': timeLeftCounter(row.value) < 24 && timeLeftCounter(row.value) >= 1,
                      'alert2': timeLeftCounter(row.value) < 1
                    }"
                  >{{moment(row.value, 'X').format('DD.MM.YYYY HH:mm')}}</span>
                  <span v-else>{{moment(row.value, 'X').format('DD.MM.YYYY HH:mm')}}</span>
                </div>
              </template>

              <template #cell(2)="row">

                <div v-if="row.item.target.online_statuses.length || row.item.active_task" style="display: flex;">
                  <div v-for="source in sourceListByParam(row.item.target.param)">
                    <tippy
                        style="display: flex; margin-right: 10px"
                        interactive
                        :animate-fill="false"
                        placement="top"
                        distant="7"
                        theme="light"
                        animation="shift-toward"
                        trigger="mouseenter"
                        offset="interactive:true"
                        arrow>
                      <template v-slot:trigger>
                        <monitoring-icons  aria-expanded="false"
                            :source="source"
                            :serviceSelected="checkForActiveTargetService(source, row.item)"
                        />
                      </template>


                      <div>
                        <div>
                          <div style="display: flex; justify-content: flex-start" v-for="innerSource in sourceListByParam(row.item.target.param)">
                            <span style="font-weight: 700; margin-right: 5px">{{ oneServiceNameCheck(innerSource)+': ' }}</span>{{targetTaskStatus(innerSource, row.item)}}
                          </div>
                        </div>
                        <div v-if="row.item.active_task" style="display: flex; justify-content: flex-start">{{$t('monitoring.started_at')+' '+ moment(row.item.active_task.started_at, 'X').format('DD.MM.YYYY, HH:mm')}}</div>
                      </div>
                    </tippy>
                  </div>
                </div>
              </template>
  
              <template #cell(1)="row">
                <target-action-online-monitoring
                    :target="row.item"
                    :is-edit="isEdit"
                    @anyloading="anyLoading"
                    :some-loading="anyloading"
                />
              </template>

            </b-table>
            <div content="noTargets" v-else-if="isError">
              <p>{{ $t('errors.' + errorText) }}</p>
            </div>
  
            <div content="noTargets" v-else>
              <p>{{ $t("monitoring.no_targets") }}</p>
            </div>
          </div>

          <add-target
              v-if="targets.length < 20"
          />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import AddTarget from "@/components/monitoring/addTarget";
import {mapGetters} from "vuex";
import target from "./target";
import UpdateTarget from "@/components/monitoring/updateTarget";
import TargetActions from "@/components/monitoring/targetActions";
import MonitoringIcons from "@/components/monitoring/monitoringIcons";
import moment from "moment";
import TargetActionOnlineMonitoring from "@/components/monitoring/targetActionOnlineMonitoring";
export default {
  components: {TargetActionOnlineMonitoring, MonitoringIcons, TargetActions, UpdateTarget, AddTarget, target},
  name: "targets",
  props: ['isLoading', 'isError', 'errorText'],
  setup() {
    return {
      moment
    }
  },
  data(){
    return {
      isEdit: false,
      loading: false,
      anyloading: false,
    }
  },
  computed: {
    ...mapGetters('monitoring', ['targets', 'sources', 'currentMonitoringCount']),
    fields(){
      if (this.isEdit){
        return  [
          {
            key: 'index',
            label: "#",
            sortable: false
          },
          {
            key: 'alias',
            label:  this.$t('monitoring.table_name'),
            sortable: false
          },
          {
            key: 'target.value',
            label:  this.$t('monitoring.table_target'),
            sortable: false
          },
          {
            key: 'active_task.expires_at',
            label:  this.$t('monitoring.task_expires_at'),
            sortable: false
          },
          {
            key: '2',
            label:  this.$t('monitoring.details'),
            sortable: false
          },
          {
            key: '1',
            label:  this.$t('monitoring.table_action'),
            sortable: false,
            'class': 'actionsBlock',
          },
        ]
      } else {
        return  [
          {
            key: 'index',
            label: "#",
            sortable: false
          },
          {
            key: 'alias',
            label:  this.$t('monitoring.table_name'),
            sortable: false
          },
          {
            key: 'target.value',
            label:  this.$t('monitoring.table_target'),
            sortable: false
          },
          {
            key: 'active_task.expires_at',
            label:  this.$t('monitoring.task_expires_at'),
            sortable: false
          },
          {
            key: '2',
            label:  this.$t('monitoring.details'),
            sortable: false
          },
          {
            key: '1',
            label:  this.$t('monitoring.table_status'),
            sortable: false,
            'class': 'actionsBlock',
          },
        ]
      }

    },
  },
  methods:{
    checkForActiveTargetService(source, target){
      if (target.active_task){
        let serviceStatus = target.active_task.services.find(service=> service.name === source)
        if (typeof serviceStatus !== 'undefined' && serviceStatus.active){
          return true
        } else {
          return false
        }
      } else {
        return false
      }

    },



    targetTaskStatus(source, target){
      let currentStatus
      let statStr ='';
      if (target.target.online_statuses.length){
         currentStatus = target.target.online_statuses.find(status => status.source === source)
      }
      if (currentStatus &&  typeof currentStatus !== 'undefined'){
        if (currentStatus.status === 'non-existent'){
          statStr = this.$t('monitoring.non-existent')
        } else if (currentStatus.status === 'offline'){
          let onlineStatus = target.target.online_statuses.find(service=> service.source === source)
          if (typeof onlineStatus !== 'undefined' && onlineStatus.last_online){
            statStr = this.$t('monitoring.target_seen') +' '+ moment(onlineStatus.last_online, 'X').format('DD.MM.YYYY, HH:mm ')
          } else {
            statStr = this.$t('monitoring.offline')
          }
        } else if (currentStatus.status === 'hidden'){
          statStr = this.$t('monitoring.hidden')
        } else if (currentStatus.status === 'online'){
          statStr = this.$t('monitoring.online')
        }
      }

      let currentTaskStatus;
      let taskStr = '';
      if (typeof target.active_task !== 'undefined' && target.active_task){
        currentTaskStatus = target.active_task.services.find(service=> service.name === source)
      }
      if (typeof currentTaskStatus !== 'undefined' && typeof currentTaskStatus.status !== 'undefined' && currentTaskStatus.status){
        if (statStr.length){
          taskStr = this.$t('monitoring.'+currentTaskStatus.status)+ ', '
        } else {
          taskStr = this.$t('monitoring.'+currentTaskStatus.status)
        }
      } else {
        if (statStr.length){
          taskStr = this.$t('monitoring.disabled')+ ', '
        } else {
          taskStr = this.$t('monitoring.disabled')
        }
      }

      if ((taskStr === '' || typeof taskStr === 'undefined') && (statStr === ''|| typeof statStr === 'undefined')){
        return this.$t('monitoring.disabled')
      } else {
        return taskStr + statStr;
      }
    },


    timeLeftCounter(val){
      let left;
      let eventTime = moment(val, 'X')
      let now = moment();
      left = eventTime.diff(now, 'hours')
      return left
    },
    sourceListByParam(param){
      if (typeof this.sources.data !== 'undefined'){
        return this.sources.data.filter(item => item.monitoring_params.includes(param) && item.monitoring_types.includes('online_status')).map(item => item.name)
      }
    },

    notFoundUserServices(status){
      let str;
      status.forEach(service=> {
        if (service.status === 'user not found'){
          if (typeof str !== 'undefined'){
            let source = service.source
            if (service.source === 'VKMonitor'){
              source = "VKontakte"
            }
            str = str + ', '+ source
          } else {
            let source = service.source
            if (service.source === 'VKMonitor'){
              source = "VKontakte"
            }
            str = source
          }
        }
      })
      return this.$t('monitoring.user_not_found')+': ' + str
    },
    oneServiceNameCheck(service){
      let source = service
      if (service === 'VKMonitor'){
        source = "VKontakte"
      }
      return source
    },
    hideUserServices(status){
      let str;
      status.forEach(service=> {
        if (service.status === 'recently'){
          if (typeof str !== 'undefined'){
            str = str + ', '+service.source
          } else {
            str = service.source
          }
        }
      })
      return this.$t('monitoring.hidden')+': ' + str
    },
    editTargets(){
      this.isEdit = !this.isEdit;
    },
    anyLoading(val) {
      this.anyloading = val;
    }
  },
  beforeDestroy() {
    this.$store.dispatch('monitoring/removeTargets');
  },
  mounted() {
  }
}

</script>

<style>
.alignLeft{
  margin-bottom: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}


.tippy-tooltip.light-theme {
  max-width: fit-content !important;
}

.alert48{
  color: #ffb012;
}
.alert24{
  color: #ff8124;
}
.alert2{
  color: #ec3305;
}


.actionsBlock {
  width: 80px;
  text-align: center;
}
.targetTable .table.table-sm td, .table.table-sm th {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.table.table-sm td, .table.table-sm th{
  white-space: break-spaces;
}
.hideText {
  width: 200px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content{
  margin-left: 1rem;
}
.not-found-info ~ .not-found-info{
display: none;
}
@media (max-width: 991px) {
  .targetTable .scroll-container {
    overflow-y: scroll;
    padding-bottom: 30px;
  }
}
</style>